import React from "react"
import Layout from "../../../components/sermonLayoutElMirage"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/SRINvc_FiI0">
    <SEO title="Jesus our Rest - Hebrews" />
  </Layout>
)

export default SermonPost
